import React, {useState, useEffect} from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Thin = () => {
  const [year, setYear] = useState()
  const getYear = () =>  setYear(new Date().getFullYear())
    useEffect(() => {
        getYear();
    }, [])
  return (
    <div className='Thin main-padding py-3'>
      <div className="container-xl px-0">
        <div className="row">
          <div className="col-sm-8 offset-sm-4 d-flex align-items-center justify-content-sm-end">
            <Link to={'/privacy-policy'} className="text-decoration-none Thin--Link text-white">Privacy policy</Link>
            <Link to={'/terms-of-use'} className="text-decoration-none Thin--Link mx-3 text-white">Terms of use</Link>
            <Link to={'/'} className="text-decoration-none Thin--Link text-white">	&#169; {year} egtos</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thin