import React from "react";
import "./style.css";

const card = [
  {
    id: 1,
    class: 'Value__card1',
    imgclass: 'Value__card__top--img1',
    title: "Faster onboarding:",
    para: `We’ve shortened a typical 8–10-week procurement process to under
        8 minutes! We do this with our AI-powered team matching
        technology that analyzes your project requirements and finds the
        right expert team for you.`,
  },
  {
    id: 2,
    class: 'Value__card2',
    imgclass: 'Value__card__top--img2',
    title: "Faster with experts:",
    para: `Because we only work with experts, you won’t have any generalists on your project learning on the job. This is a breath of fresh air if you’ve previously worked with consultancies that employ generalists to do most of the work.`,
  },
  {
    id: 3,
    class: 'Value__card3',
    imgclass: 'Value__card__top--img3',
    title: "Faster with teams:",
    para: 'Because we deliver teams, not just individuals, we help you accelerate and scale far faster than any consultancy-on-demand platform that delivers individuals.',
  },
];

const Section3 = () => {
  return (
    <div className="Value main-padding">
      <div className="container-xl px-0 pt-sm-5 pt-3 pb-5">
        <h1 className="Value__heading my-sm-5 my-4">See value, quickly.</h1>
        <div className="row pb-sm-5 pb-3 pt-sm-4 flex-row">
          {card.map((item)=>(
            <div className="col-lg-4 col-sm-6 mb-lg-0 mb-4" key={item.id}>
            <div className={`Value__card ${item.class} pt-lg-5 pt-sm-4 pt-2 pe-xxl-0 pe-lg-3 pe-2 h-100`}>
              <div className="Value__card__top">
              <img
                src={`/images/value_${item.id}.svg`}
                alt="value"
                className={`Value__card__top--img ${item.imgclass} mb-4`}
              />
              </div>
              <h6 className="Value__card__title ">{item.title}</h6>
              <p className="Value__card__para">
                {item.para}
              </p>
            </div>
          </div>
          ))}
        </div>
        <button className="yellow_btn py-2 px-4 rounded-pill">Are you ready to accelerate?</button>
      </div>
    </div>
  );
};

export default Section3;
