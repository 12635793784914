import React from "react";
import "./style.css";
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";

function Section8() {
  return (
    <div className="Section7 main-padding pb-3">
      <div className="container-xl px-0 py-sm-5 py-4">
        <div className="Section7__content pt-4">
          <h1 className="Section7__content__heading Section1__content__heading mb-sm-5 mb-4">
            Keep in touch
          </h1>
          <p className="Section7__content__para Section1__content__para mb-0">
            Subscribe to our newsletter
          </p>
        </div>

        <form action="/">
          <div className="row px-2 mb-2">
            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard-helperText"
                    label="First name"
                    variant="filled"
                    className="w-100"
                    size="small"
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard"
                    label="Last name"
                    variant="filled"
                    className="w-100"
                    size="small"
                    type="text"
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard"
                    label="Company"
                    variant="filled"
                    className="w-100"
                    size="small"
                    type="text"
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard"
                    label="Job title"
                    variant="filled"
                    className="w-100"
                    size="small"
                    type="text"
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard"
                    label="Contact number"
                    variant="filled"
                    className="w-100"
                    size="small"
                    type="text"
                  />
                </div>
            </div>

            <div className="col-md-4 col-sm-6 ps-sm-0 ps-1 pe-sm-3 pe-1 mt-3">
              
                <div className="Section7__input w-100">
                  <TextField
                    required
                    id="standard"
                    label="Email"
                    variant="filled"
                    className="w-100"
                    size="small"
                    type="email"
                  />
                </div>
            </div>
          </div>
          <small className="Section7--small">
          *Required. Read our privacy policy {""}
          <Link to={"/privacy-policy"} className="Section7--small--Link">
            here.
          </Link>
        </small> <br />
          <a href="mailto:contact@egtos.ch" className="text-decoration-none">
          <button className="zinc_btn mt-4 px-4 py-2 rounded-pill">
              Submit
          </button>
          </a>
        </form>
        
      </div>
    </div>
  );
}

export default Section8;
