import React from "react";
import "./style.css";

const Banner = (prop) => {
  return (
    <>
      <div
        className="banner2 banner2-absolute pt-5"
        style={{
          backgroundImage: `url(${prop.image})`,
        }}
      >
        <div className="banner2__overlay d-lg-flex align-items-center h-100">
          <div className="container-xl px-0 h-100">
            <div className=" main-padding h-100">
              <div className="banner2__content d-flex flex-column justify-content-center pt-5">
                <h1 className="banner2__content__heading">
                  {prop.heading}
                </h1>
                <p className="banner2__content__para mt-xl-3 mt-2">
                  {prop.para}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner2"></div>
    </>
  );
};

export default Banner;
