import React from "react";
import "./style.css";

const Terms = () => {
  return (
    <div className="Terms py-5 d-flex justify-content-center main-padding">
      <div className="Terms__container">
        <p className="Terms__container__para mb-4">
          This Terms and Conditions clearly define how we, egtos GmbH, uses,
          stores, obtains, and use your personal information when you use or
          interact with our website, This Terms and Condition is effective{" "}
          <br />
          <span className="fw-bold">01 day of January,</span>
        </p>
        <p className="Terms__container__para mb-4">
          2022. Please read this Terms and Condition carefully. We recommend
          that you print a copy of this Terms and Condition and any applicable
          future versions from time to time for your records
        </p>
        <p className="Terms__container__para mb-4">
          These Terms and Conditions must be read in conjunction with the
          corresponding Privacy Policy.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">Our services</h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH is a AI-driven platform designed to help Clients’ build
          global experienced and diverse consulting teams on Demand. egtos GmbH
          also uses complex algorithms to build teams based on clients’ project
          needs, and level of complexity and budgetary requirements.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">Eligibility</h3>
        <p className="Terms__container__para mb-4">
          For you to be eligible to use our services or access the contents in
          this website, you must be 13 years or older to use or access the
          Service. But if you are below the age of 13 and wish to use the
          Service you must submit to egtos GmbH in writing consent to do so by a
          parent or guardian and egtos GmbH must approve your use of the Service
          in writing. The Service may not be available to any Users previously
          removed from the Service by egtos GmbH
        </p>
        <p className="Terms__container__para mb-4">
          You further represent and warrant that you are under no legal
          disability or contractual restriction that prevents you from entering
          into this Agreement.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Rules you must obey
        </h3>
        <p className="Terms__container__para mb-4">
          If you find something that violates our rules please let us know, and
          we’ll review it. Your purpose of accessing this website is to use the
          information obtain as any market research for a competing business;
          You will not disassemble any software or processes accessible through
          our Platform; You will comply with all applicable laws in your use of
          the Platform and will not use the Platform for any unlawful purpose;
          You agree not to interrupt the proper operation of the Platform by the
          application of any virus, device, data collection or transmission
          mechanism, software or routine, or access or attempt to gain control
          to any Content, information, files, or passwords related to egtos GmbH
          through hacking, password or information mining, or through other
          means; You agree not to block, or in any way disturb with any
          advertisements and/or safety features on our Platform; You will not
          use any robot, scraper, or other automated means to gain access to our
          website and Platform for any purpose without egtos GmbH express
          written permission; provided, however, we grant the operators of
          public search engines permission to use spiders to copy materials from
          the public portions of our website for the sole purpose of and solely
          to the extent necessary for creating publicly-available searchable
          indices of the materials, but not caches or archives of such
          materials.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Your accounts
        </h3>
        <p className="Terms__container__para mb-4">
          To access our service, you may need to create egtos GmbH account on
          our Website. This account gives you access to the features and
          functionality of the relevant Service that we may establish and
          maintain from time to time and in our sole discretion. We may maintain
          different types of accounts for different types of Users. If you open
          an account on the Websites on behalf of a company, organization, or
          other entity, then (a) “you” include you and that entity, and (b) you
          represent and warrant that you are an authorized representative of the
          entity with the authority to bind the entity to this Agreement, and
          that you agree to this Agreement on the entity’s behalf. To the extent
          that you connect to our Websites via a third-party service (for
          example through Facebook), you give us permission to access and use
          your information from that service as permitted by that service, and
          to store your log-in credentials and information for that service. You
          must only use third party service accounts owned by you, and not by
          any other person or entity. You may never use another User’s account
          without permission. When creating your account, you must provide
          accurate and complete information. You are solely responsible for the
          activity that occurs on your account, and you must keep your account
          password secure. We encourage you to use “strong” passwords with your
          account. You may not allow any other person to access or use the
          Service with your unique username, password, or other security code.
          You must notify egtos GmbH immediately of any breach of security or
          unauthorized use of your account. egtos GmbH will not be liable for
          any losses caused by any unauthorized use of your account. egtos GmbH
          reserves the right to terminate accounts that are inactive for an
          extended period of time, as we reasonably determine, with or without
          notice. egtos GmbH may permanently or temporarily terminate or suspend
          your access to the Service without notice and liability for any
          reason, including if in our sole determination you violate any
          provision of these Terms and Conditions, or for no reason. If your
          account is terminated for any reason, you must obtain written
          authorization from egtos GmbH prior to establishing another account.
          If you attempt to establish another account without obtaining such
          authorization, egtos GmbH may permanently ban you from the Service. A
          person may not have more than one active User account at any time
          without the written consent of egtos GmbH in each instance. You are
          solely responsible for your interactions with other egtos GmbH Users.
          egtos GmbH reserves the right, but have no obligation, to monitor
          disputes between you and other Users. egtos GmbH will not have any
          liability for your interactions with other Users, or for any User’s
          action or inaction.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          General obligations of use
        </h3>
        <p className="Terms__container__para fw-bold mb-2">
          Users are not permitted to engage in any of the following prohibited
          activities:
        </p>
        <p className="Terms__container__para mb-4">
          hacking to the Service or any content other than your own without the
          prior written consent of egtos GmbH; attempting to compromise the
          system integrity or security or decipher any transmissions to or from
          the servers running the Service; Copying, distributing, or disclosing
          any part of the Service in any medium, including without limitation by
          any automated or non-automated “scraping using any automated system,
          including without limitation “robots,’ “spiders,” etc., to access the
          Service; transmitting spam, chain letters, or other unsolicited email;
          taking any action that imposes, or may impose at our sole discretion
          an unreasonable or disproportionately large load on our
          infrastructure; uploading invalid data, viruses, worms, trojans or
          other malicious software through the Service; accessing any content on
          the Service through any technology or means other than those provided
          or authorized by the Service; collecting or harvesting any personally
          identifiable information, including account names, email addresses,
          from the Service, accessing without authority, interfering with,
          damaging or disrupting any part of the Web sites; bypassing the
          measures, we may use to prevent or restrict access to the Service,
          including without limitation features that prevent or restrict use or
          copying of any content or enforce initiations use of the Service or
          the content therein; using the Service to support, incite or promote
          discrimination, hostility or violence; using any egtos GmbH trademark
          or any variant thereof including misspellings as a domain name or as
          part of a domain name, as a metatag, keyword, or any other type of
          programming code or data; adopting or using, without our prior written
          consent, any word or mark which is similar to or likely to be confused
          with egtos GmbH trademarks, hacking to the Service or any content
          other than your own without the prior written consent of egtos GmbH,
          making derivative works based on the Websites; removing or altering
          any copyright, trade mark, logo or other proprietary notice or label
          appearing on the Websites, or materials provided on the Websites;
          impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity interfering with the proper working
          of the Platform; copying, imitating or using, in whole or in part, the
          look and feel of the Service (including but not limited to all page
          headers, custom graphics, button icons, and scripts) without the prior
          written consent of egtos GmbH
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Availability and warranties
        </h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH has the sole discretion modify, review, and update terms
          and conditions including its Services (or any part thereof, including
          features or functionality) with or without notice. By using our
          website, you accept that we will not be liable to any third party,
          including you, should we decide to modify, suspend or discontinuance
          the Services. egtos GmbH does not warrant that our website or platform
          will be always compatible with all hardware and software which you may
          use. egtos GmbH Warrants that it will not be responsible for any
          damage, or viruses or other code that may affect, any equipment
          (including but not limited to your mobile device), software, data or
          other property as a result of your access to or use of the Service or
          your obtaining any material from, or as a result of using, the
          Service. We will not be held liable for the actions of third parties.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">Storage</h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH does not store your information other than to process our
          website use requests, however, should we store your information for
          whatever reason, we may, with or without prior notice, set or change
          the maximum period of time that documents, data or content will be
          retained by the Service and the maximum storage space that will be
          allotted on egtos GmbH’ servers or systems on your behalf. You hereby
          agree that egtos GmbH has no liability for the deletion or failure to
          store any documents, data or other content maintained or uploaded in
          our Platform.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Ordering and delivery
        </h3>
        <p className="Terms__container__para mb-4">
          You may place an order for particular Services through the Websites,
          by logging into your registered account. We will deliver your services
          at the agreed date after full payment of the agreed about.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">Fees</h3>
        <p className="Terms__container__para mb-4">
          The Services are offered at the prices and fees set forth on the
          Websites (and customer portals accessible through the Website) or as
          otherwise notified by us to you, and as those prices and fees are
          amended by us from time to time. By requesting Services, you agree to
          pay the applicable prices and fees for those Services. The prices and
          fees are exclusive of any applicable taxes. You will pay any
          applicable taxes, if any, relating to any such purchases, licenses,
          royalties, transactions, or other monetary transaction interactions in
          connection with the Services.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Payment terms and payment method
        </h3>
        <p className="Terms__container__para mb-4">
          You may pay your order by a valid credit card, or by any other method
          that egtos GmbH may determine from time to time. egtos GmbH accepts
          payment by Visa, Mastercard and American Express, but egtos GmbH may
          change the cards that we accept from time to time
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Rejection and cancellation by egtos GmbH
        </h3>
        <p className="Terms__container__para mb-2">
          In addition to any Client General Service Agreement, if an order for
          Services has been placed by you with us, we may in our absolute
          discretion reject an order (or any part thereof) where:
        </p>
        <p className="Terms__container__para mb-4">
          Client has violated the Terms and Conditions or any other subsidiary
          agreement thereto. egtos GmbH has not received payment for Services
          previously provided to you: or in the event of a “Force Majeure Event”
          (for the purpose of this terms, Force Majeure means, any event beyond
          the reasonable control of egtos GmbH, in consequence of which we
          cannot reasonably be required to perform obligations under this
          Agreement including but not limited to, fire, outbreaks of war,
          pandemics, epidemics, acts of hostility, terrorist activity, national
          emergency, government action, or any act of God, where any of our
          employees, experts who are directly involved in providing the Services
          are sick or suffer some other emergency or unexpected event). You can
          request to cancel a service in writing by contacting us via email at
          contact@egtos.com or through the Platform and shall be subject to our
          Client General Service Agreement.
        </p>
        <h3 className="Terms__container__heading fw-bold mb-2">
          Rejection and cancellation by egtos GmbH
        </h3>
        <p className="Terms__container__para mb-4">
          Subject to the Client General Service Agreement and Provided, egtos
          Inc. has received full payment of the relevant service of Clients, the
          Intellectual Property Rights in the transcripts, caption files or
          other directly relevant files that Global Consulting delivers you for
          an order (“Delivered Materials’”) are owned by you. You hereby grant
          us a perpetual, irrevocable, non-exclusive, transferable,
          royalty-free, worldwideto store and use the ‘Delivered Materials’ for
          the purposes of archiving and quality assurance testing and controls.
          In respect of the information or content, ‘including but not limited
          to images, videos, audio recordings, illustration or texts,
          regardless’ of the medium, that you share, use, upload or submit in
          connection with your access to or use of the Service ( “User
          Content”), you agree, represent and warrant that you own (or have
          sufficient lights to) all right, title and interest in and to such
          User Content, including, without limitation, all Intellectual Property
          Rights contained therein. By uploading or providing any User Content
          you hereby grant and will grant egtos GmbH and its related and
          affiliated companies, contractors and partners a nonexclusive,
          worldwide, royalty free, fully paid up, transferable,
          sub-license-able, perpetual, irrevocable license to copy, display,
          upload, perform, distribute, store, modify and otherwise use your User
          Content in connection with the operation of the Service and as
          otherwise permitted under this Agreement, including for clarity’s sake
          the Privacy Policy, in any form, medium or technology now known or
          later developed. In all cases, you agree, represent and warrant that
          ‘User Content’ and our (and our related and affiliated companies, and
          contractor’s) use of the ‘User Content will not violate any law or
          infringe any rights of any third party’, including but not limited to
          any ‘Intellectual Property Rights and privacy and publicity rights’.
          You must not upload, use, share or submit any User Content in our
          website and other Platform that: 1.that violates third-party rights of
          any kind, including without limitation any Intellectual Property
          Rights or rights of privacy or publicity. contains any information or
          content that we deem to be unlawful, harmful, abusive, racially or
          ethnically offensive, defamatory, infringing, invasive of personal
          privacy or publicity rights, harassing, humiliating to ‘other people
          (publicly or otherwise), libelous, threatening, hostile, obscene
          violent’, or that which provokes violence or hostility-, profane, or
          otherwise objectionable may create a risk of harm, loss, physical or
          ‘mental injury, emotional distress, death, disability, disfigurement,
          or physical’ or mental illness to you, to any other person, or to any
          animal; may create a risk of any other loss or damage to any person or
          property; seeks to harm or exploit children including by exposing them
          to inappropriate content, asking for personally identifiable details
          or otherwise, may constitute or contribute to a crime or tort:
          contains any information which discriminates against others based on
          race, religion, sex, sexual orientation, age, disability, ancestry or
          national origin: contains any information or content that is illegal
          (including, without limitation, the disclosure of insider information
          under securities law or of ‘another party’s trade secrets’); (h)
          contains any information or content that you do not have a right to
          make available under any law or under contractual or fiduciary
          relationships; or contains any information or content that you know is
          nor correct and current. egtos GmbH reserves ‘the right to reject
          and/or remove any User’. Content that egtos GmbH believes, in its sole
          discretion. You ‘hereby agree that egtos GmbH may advertise you as a
          customer of our services and accordingly, you hereby grant us a
          non-exclusive license to use your name and trad mark for inclusion in
          our marketing, advertising, and or publicity materials’.
        </p>
      </div>
    </div>
  );
};

export default Terms;
