import React from "react";
import Banner from "../pageComponents/Home/Banner/Banner";
import Image from "../pageComponents/Home/Banner/Image";
import Section1 from "../pageComponents/Home/Section1/Section1";
import Section2 from "../pageComponents/Home/Section2/Section2";
import Section3 from "../pageComponents/Home/Section3/Section3";
import Section4 from "../pageComponents/Home/Section4/Section4";
import Section5 from "../pageComponents/Home/Section5/Section5";
import Section6 from "../pageComponents/Home/Section6/Section6";
import Section7 from "../pageComponents/Home/Section7/Section7";
import Section8 from "../pageComponents/Home/Section8/Section8";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import CookieConsent from "react-cookie-consent";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="banner-height bg-transparent"></div>
      <Banner />
      <Image />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#005fbe", fontSize: "13px", borderColor:"#005fbe"  }}
        expires={150}
      >
        <h4>Cookies</h4>
        This website uses cookies to improve your experience and give us insights in how our website is used, we may also use them to provide promotional materials, communications and content. By using our website you agree to the use of cookies. Please read our Privacy Policy for further information and how to disable cookies.
      </CookieConsent>
    </>
  );
};

export default Home;
