import React from "react";
import "./style.css";

function Section2() {
  return (
    <div className="Section2 overflow-hidden">
      <div className="container-xl px-0 pt-sm-4 pb-4">
        {/* row1 */}
        <div className="row">
          <div className="col-lg-5">
            <div className="Section2__left main-padding d-flex justify-content-lg-start justify-content-center mb-lg-0 mb-sm-5 mb-4">
              <img
                src="/images/section2_1.jpg"
                alt="power"
                className="Section2__left--img d-lg-block d-none"
              />
              <img
                src='/images/section2_1mob.jpg'
                alt="power"
                className="Section2__left--img d-lg-none d-block"
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-items-center px-xxl-0 ps-xl-4">
            <div className="Section2__content  main-padding ps-lg-0">
              <h1 className="Section2__content__heading Section1__content__heading">
                We power scale,{" "}
                <span className="Section2__content__heading--blue">fast.</span>
              </h1>
              <p className="Section2__content__para Section1__content__para mt-sm-4 mt-3">
                <span className="Section2__content__para--bold">
                  We shorten a typical 8-10 week procurement process to under 8
                  minutes!
                </span>{" "}
                <br />
                Because we are able to deliver teams, not just individuals, we
                help you accelerate and scale far faster than any
                consultancy-on-demand platform, or traditional consultancies.
                Our engagement expert balanced teams deliver the value only we
                can.
              </p>
            </div>
          </div>
        </div>

        {/* row2 */}
        <div className="row my-sm-5 my-4">
          <div className="col-lg-5 order-lg-2 order-1">
            <div className="Section2__left main-padding d-flex justify-content-lg-end justify-content-center mb-lg-0 mb-sm-5 mb-4">
              <img
                src='/images/section2_2.jpg'
                alt="power"
                className="Section2__left--img d-lg-block d-none"
              />
              <img
                src='/images/section2_2mob.jpg'
                alt="power"
                className="Section2__left--img d-lg-none d-block"
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-items-center order-lg-1 order-2 px-xxl-0 pe-xl-4">
            <div className="Section2__content text-lg-end  main-padding pe-lg-0">
              <h1 className="Section2__content__heading Section1__content__heading">
                Work with the best. <br />
                <span className="Section2__content__heading--blue">Be rewarded.</span>
              </h1>
              <p className="Section2__content__para Section1__content__para mt-sm-4 mt-3">
                We offer exciting work with dynamic teams of like-minded experts
                from around the world. Experts work on projects they choose that
                align with their deep expertise and interests. Unlike any other
                platform, our people are incentivized with equity in the egtos
                platform. Our people are important.
              </p>
            </div>
          </div>
        </div>

        {/* row3 */}
        <div className="row">
          <div className="col-lg-5">
            <div className="Section2__left main-padding d-flex justify-content-lg-start justify-content-center mb-lg-0 mb-sm-5 mb-4">
              <img
                src='/images/section2_3.jpg'
                alt="power"
                className="Section2__left--img d-lg-block d-none"
              />
              <img
                src='/images/section2_3mob.jpg'
                alt="power"
                className="Section2__left--img d-lg-none d-block"
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-items-center px-xxl-0 ps-xl-4 ">
            <div className="Section2__content  main-padding ps-lg-0">
              <h1 className="Section2__content__heading Section1__content__heading">
                Powered by experts.{" "}
                <span className="Section2__content__heading--blue">
                  And Artificial Intelligence.
                </span>
              </h1>
              <p className="Section2__content__para Section1__content__para mt-sm-4 mt-3">
                Our unique and leading AI technology builds the best expert
                teams for each project. Our platform eliminates bias, promotes
                diversity and creates efficient and high-performing teams. It
                also learns about how our experts work enabling us to build an
                intelligent system so you get the best results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
