import React from 'react'
import './style.css'

const card = [
    {
        id: 1,
        title: 'Agile Ways of Working'
    },
    {
        id: 2,
        title: 'Corporate Development & Strategy'
    },
    {
        id: 3,
        title: 'Cultural Transformation'
    },
    {
        id: 4,
        title: 'Cybersecurity & Data Privacy'
    },
    {
        id: 5,
        title: 'Digital and IT strategy'
    },
    {
        id: 6,
        title: 'Operating Model'
    },
    {
        id: 7,
        title: 'Diversity, Equality & Inclusion on their projects'
    },
    {
        id: 8,
        title: 'Data Science & Analytics'
    },
    {
        id: 9,
        title: 'M&A Transaction Services'
    },
    {
        id: 10,
        title: 'People Strategy'
    },
    {
        id: 11,
        title: 'Operational Excellence'
    },
    {
        id: 12,
        title: 'Organizational Design'
    },
]


const Section6 = () => {
  return (
    <div className='Small main-padding'>
        <div className="container-xl px-0 py-sm-5 pb-4 pt-5">
            <div className="row">
                {card.map((item)=>(
                    <div className="col-xl-2 col-md-3 col-sm-4 col-6 mb-xxl-5 mb-4" key={item.id}>
                    <div className="Small__card pe-2">
                        <div className="Small__card__top mb-3 d-flex align-items-end">
                            <img src={`/images/Service_small_${item.id}.svg`} alt="" className="Small__card__top--img" />
                        </div>
                        <h5 className="Small__card__para Teamwork__card__para">{item.title}</h5>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Section6