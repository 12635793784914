import React from "react";
import Banner from "../components/Banner/Banner";
import Terms from "../pageComponents/Terms/Terms";
import Thin from "../components/FooterThin/Thin";
import Navbar from "../components/Navbar/Navbar";

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <Banner image="/images/terms.jpg" heading="Terms of use" />
      <Terms />
      <Thin />
    </>
  );
};

export default TermsOfUse;
