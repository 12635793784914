import React from "react";
import Banner from "../components/Banner/Banner";
import Policy from "../pageComponents/Policy/Policy";
import Thin from "../components/FooterThin/Thin";
import Navbar from "../components/Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Banner image="/images/terms.jpg" heading="Privacy policy" />
      <Policy />
      <Thin />
    </>
  );
};

export default PrivacyPolicy;
