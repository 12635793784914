import React, { useState, useEffect } from "react";
import { HiOutlineArrowUp } from "react-icons/hi";
import "./style.css";

const Footer = () => {
  const [year, setYear] = useState();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const getYear = () => setYear(new Date().getFullYear());
  useEffect(() => {
    getYear();
  }, []);
  return (
    <div className="Footer main-padding bg-black py-4">
      <div className="container-xl px-0 ">
        <div className="row">
          <div className="col-lg-4 col-md-6 order-1 mb-lg-0 mb-3">
            <p className="mb-0 text-white-50 fs-6 fm">
              For business related requests please contact:
            </p>
            <a
              href="mailto:contact@egtos.ch"
              className="text-white text-decoration-none fw-light fs-6 fm"
            >
              contact@egtos.ch 
            </a>
          </div>
          <div className="col-lg-4 col-md-6 order-lg-2 order-md-3 order-2 mb-md-0 mb-3">
            <p className="mb-0 text-white-50 fs-6 fm">
            Sevogelstrasse 102
            </p>
            <p className="mb-0 text-white-50 fs-6 fm">4052, Basel</p>
            <p className="mb-0 text-white-50 fs-6 fm">Switzerland, Europe</p>
          </div>
          <div className="col-lg-4 col-md-6 order-lg-3 order-md-2 order-3 d-flex justify-content-md-end justify-content-between">
            <div>
              <p className="mb-1 text-white fw-light fs-6 fm text-md-end">
                &#169; Copyright {year} All rights reserved
              </p>
              <div className="d-flex justify-content-md-end">
                <a
                  href="/privacy-policy"
                  className="text-white text-decoration-none fw-light fs-6 fm pe-2 Footer__divider Footer__link"
                >
                  Privacy policy
                </a>
                <a
                  href="/terms-of-use"
                  className="text-white text-decoration-none fw-light fs-6 fm ps-2 Footer__link"
                >
                  Terms of use
                </a>
              </div>
            </div>
            <HiOutlineArrowUp
              className="fs-1 ms-3 pointer mt-md-0 mt-3 transition Scroll__top"
              onClick={handleScrollToTop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
