import React from "react";

const Policy = () => {
  return (
    <div className="Policy main-padding py-5 d-flex justify-content-center">
      <div className="Terms__container">
        <p className="Terms__container__para mb-4">
          This Privacy Policy governs the manner in which the Provider (“egtos
          Inc.,” “Us” Provider” or “we”) collects, uses, maintains, and
          discloses information (including personal information) collected from
          users (each, a “User” customer, or “you”) of our website located at
          our website (“Website”), mobile applications and other services
          provided by us or any of its subsidiaries (collectively, “Services”).
          You agree that egtos GmbH may use your data accordingly. egtos GmbH
          allows you to products (hereinafter referred to as “Services”) via the
          platforms or the various apps for mobile devices. You can either place
          your order as a guest or register as a user and enjoy the benefits of
          a personal account and other services. We would like to detail the
          privacy policy as follows. egtos GmbH also uses the personal data for
          statistical and market analysis purposes. In an anonymous form, for
          example, statistics on customer groups, delivery areas, market areas
          are compiled and evaluated. For special services or actions (eg
          special actions), additional data protection information may apply. We
          will inform you about these at the beginning of the use process of the
          respective service or action. Your use of this Website or any related
          Services, or otherwise providing us information, you agree to our
          collection, storage, use and disclosure of your personal information
          and other information as described in this Privacy Policy. If we are
          not able to collect, store, use, and disclose information about you as
          set out in this Privacy Policy, we may not be able to provide you with
          access to the Website or Services.
        </p>

        <h3 className="Terms__container__heading mb-2">
          Changes to this privacy policy
        </h3>
        <p className="Terms__container__para mb-4">
          WE HAVE THE DISCRETION TO UPDATE THIS PRIVACY POLICY AT ANY TIME. WHEN
          WE DO, WE WILL REVISE THE UPDATED DATE AT THE BOTTOM OF THIS PAGE. WE
          ENCOURAGE USERS TO FREQUENTLY CHECK THIS PAGE FOR ANY CHANGES TO STAY
          INFORMED ABOUT HOW WE ARE HELPING TO PROTECT THE PERSONAL INFORMATION
          WE COLLECT. YOU ACKNOWLEDGE AND AGREE THAT IT IS YOUR RESPONSIBILITY
          TO REVIEW THIS PRIVACY POLICY PERIODICALLY AND BECOME AWARE OF
          MODIFICATIONS.
        </p>
        <h3 className="Terms__container__heading mb-2">
          Non-personal information
        </h3>
        <p className="Terms__container__para mb-4">
          We may collect non-personal information about Users whenever they
          interact with our Website or Services. Non-personal information may
          include, but is not limited to, the browser name, the type of computer
          or device, and the technical information about Users’ means of
          connection to our Website or Services, such as the operating system,
          the Internet service providers utilized, mobile network information,
          and other similar information.
        </p>
        <h3 className="Terms__container__heading mb-2">Web browser cookies</h3>
        <p className="Terms__container__para mb-4">
          Our Website may use “cookies” to enhance User experience. Our web
          browsers keep cookies on Users hard drive for data-keeping only and
          sometimes to trace information about Users. However, you may choose to
          set your web browser not to accept cookies or to alert you whenever
          cookies are being sent. If you don’t do so, we note that some parts of
          the Website may not function properly.
        </p>
        <h3 className="Terms__container__heading mb-2">
          How we use information collected
        </h3>
        <p className="Terms__container__para mb-2">
          In general, the personal information we collect is used for the
          primary purposes for which it was collected, including:
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To deliver our Services to you
        </p>
        <p className="Terms__container__para mb-2">
          We may use your personal data to send the deliverables and any other
          goods, services, or data that you request from us or any other user of
          our Website or Services.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To prevent, detect, and mitigate illegal activities
        </p>
        <p className="Terms__container__para mb-2">
          We may use the information you provided to us to prevent, detect, and
          mitigate fraudulent, counterfeit, or illegal activities.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To provide or improve customer service
        </p>
        <p className="Terms__container__para mb-2">
          We may use the information you provided to us to enable us respond to
          our clients’ service requests and support needs more efficiently.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To personalize user experience
        </p>
        <p className="Terms__container__para mb-2">
          We may use the information you provided to us in the aggregate to
          understand how our Users as a group use our Services and resources
          provided on our Website.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To improve our Website
        </p>
        <p className="Terms__container__para mb-2">
          We may use the information you provided to us to get feedback.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To run a promotion, contest, survey, or other Website feature
        </p>
        <p className="Terms__container__para mb-2">
          We may use the information you provided to us to transmit information
          that Users of our services have agreed to receive about topics we
          think will be of interest to them.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          To send periodic emails
        </p>
        <p className="Terms__container__para mb-2">
          We use the information you provided to us such as your email addresses
          to send you information and updates to Clients’ related services. Your
          email address may also be used to answer your inquiries, questions,
          and/or other requests. If you agree to be on our mailing lists, you
          will receive emails that may include company news, updates, related
          product or service information, etc.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">
          Unsubscribe/Opt-out
        </p>
        <p className="Terms__container__para mb-2">
          If at any time the User would like to unsubscribe from receiving
          future promotion, content, survey, feature, or other marketing
          information or emails, we include detailed unsubscribe instructions at
          the bottom of each email. You may not, however, unsubscribe from
          emails about your account status or order status.
        </p>
        <p className="Terms__container__para mb-2 fw-bold">Other uses</p>
        <p className="Terms__container__para mb-4">
          We may also use your personal information for a secondary purpose that
          is related to a purpose for which we collected it, where you would
          reasonably expect us to use your personal information for that
          secondary purpose.
        </p>
        <h3 className="Terms__container__heading mb-2">
          How we protect your information
        </h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH uses proper data collection, storage and processing
          practices, and information protection measures that are designed to
          protect against unauthorized access, alteration, disclosure, or
          destruction of your private data, username, password, transaction
          information, and data stored on our Website or Services.
        </p>
        <h3 className="Terms__container__heading mb-2">Storage</h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH does not store your information other than to process our
          website use requests, however, should we store your information for
          whatever reason, we may, with or without prior notice, set or change
          the maximum period of time that documents, data or content will be
          retained by the Service and the maximum storage space that will be
          allotted on egtos GmbH’ servers or systems on your behalf. You hereby
          agree that egtos GmbH has no liability for the deletion or failure to
          store any documents, data or other content maintained or uploaded in
          our Platform.
        </p>
        <h3 className="Terms__container__heading mb-2">
          Ordering and delivery
        </h3>
        <p className="Terms__container__para mb-4">
          You may place an order for particular Services through the Websites,
          by logging into your registered account. We will deliver your services
          at the agreed date after full payment of the agreed about.
        </p>
        <h3 className="Terms__container__heading mb-2">
          Disclosure related to Our Terms of Condition Agreement
        </h3>
        <p className="Terms__container__para mb-4">
          egtos GmbH believes in good faith that a User has breached the Terms
          of condition or any other egtos GmbHagreement, we may disclose such
          User’s personal information as found necessary and relevant to
          pursuing a claim or preventing further injury to egtos GmbH, its
          Users, and others. Disclosure may be made without notice to User.
        </p>
        <h3 className="Terms__container__heading mb-2">
          Third Party Websites and Advertising
        </h3>
        <p className="Terms__container__para mb-2 fw-bold">
          Third party websites
        </p>
        <p className="Terms__container__para mb-2">
          Users of our website may find ads on our Website that connects to the
          sites and services of our partners, suppliers, advertisers, sponsors,
          licensors, and other third parties. We do not control the content or
          links that appear on these sites and are not responsible for the
          practices employed by websites linked to or from our Website. In
          addition, these sites or services, including their content and links,
          may be constantly changing. These sites and services may have their
          own privacy policies and customer service policies. Browsing and
          interacting on any other website, including websites which have a link
          to our Website, is subject to that website’s own terms and policies.
          We are not a party to those agreements; they are solely between you
          and the third party.
        </p>
        <p className="Terms__container__para fw-bold mb-2">Advertising</p>
        <p className="Terms__container__para mb-5">
          Advertisements appearing on our Website may be delivered to Users by
          advertising partners, who may set cookies. The cookies we set may
          allow the advertising server to recognize your computer each time you
          send an online advertisement to compile non-personal information about
          you or others who use your computer. This data allows advertising
          networks to, among other things, deliver targeted advertisements that
          they believe will be of most interest to you. This Privacy Policy does
          not cover the use of cookies by any advertiser.
        </p>
        <p className="Terms__container__para fw-bold mb-0">– 2022.04.06</p>
      </div>
    </div>
  );
};

export default Policy;
