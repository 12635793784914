import React from "react";
import "./style.css";

const card = [
  {
    id: 1,
    title: "HEALTHCARE",
    para: "The challenges in healthcare require that expertise is harnessed from an array of other specialisms.",
  },
  {
    id: 2,
    title: "INDUSTRIAL & MATERIALS",
    para: "Many of our experts have experience in the Industrials sector either from previously working in industry or with top-tier consultancies in this space.",
  },
  {
    id: 3,
    title: "FINANCIAL SERVICES",
    para: "Financial services face many challenges, not least in digital transformation, cyber security and business transformation, but also in changing consumer expectations and behavior, and digital disruption.",
  },
  {
    id: 4,
    title: "ENERGY & UTILITIES",
    para: "Today’s energy market is changing fast. De-carbonization is a priority, digital is transforming delivery and customer service and geopolitical issues are shaping energy supply.",
  },
  {
    id: 5,
    title: "TECHNOLOGY, MEDIA & TELECOMMUNICATIONS",
    para: "This sector is fast-moving, and businesses need to harness new technology innovation to better connect customers, manage remote talent, manage and analyze data, all in a secure environment.",
  },
  {
    id: 6,
    title: "REAL ESTATE",
    para: "While Real Estate is strong, it has its own unique challenges in our changing markets.",
  },
  {
    id: 7,
    title: "CONSUMER",
    para: "Our experts come from a variety of specialisms including Consumer Discretionary and Consumer Staples and have worked for a wide range of global firms transforming the Customer Experience",
  },
  {
    id: 8,
    title: "PRIVATE EQUITY",
    para: "Private Equity requires specialist knowledge and fast. Our experts are adept at working with with Private Equity teams to inform investment decisions, and to turnaround businesses in a range of sectors.",
  },
];

const Section4 = () => {
  return (
    <div className="Industry main-padding py-5">
      <div className="container-xl px-0">
      <h1 className="Value__heading darkblue">Serious experience</h1>
        <p className="Industry__para Description__para Section1__content__para mb-4">
          Our people have expertise across industries, organizations and
          geographies. Whatever your industry, and whatever your challenge, we
          are confident we can find the right expert team to help you achieve
          success.
        </p>

        <div className="row pt-sm-5 pt-4 px-sm-0 px-2">
          {card.map((item)=>(
            <div className="col-lg-3 col-md-4 col-6 mb-lg-5 mb-4 px-sm-2 px-1" key={item.id}>
            <div className="Industry__card h-100">
              <div className="Industry__card__top">
                <img
                  src={`/images/Industry_${item.id}.jpg`}
                  alt=""
                  className="Industry__card__top--img"
                />
              </div>
              <div className="Industry__card__content py-3 px-sm-3 px-2">
                <h6 className={`Industry__card__content__heading Industry__card__content__heading${item.id}`}>{item.title}</h6>
                <p className="Industry__card__content__para Services__card__para">
                  {item.para}
                </p>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section4;
