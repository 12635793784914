import React from "react";
import "./style.css";
import { InlineWidget } from "react-calendly";

const Demo = () => {
  return (
    <div className="Demo main-padding bg-black">
      <div className="container-xl px-0 py-5 ">
        <div className="banner2__content d-flex flex-column justify-content-center pt-5 mt-sm-5 pb-sm-0 pb-5">
          <h1 className="Demo__heading banner2__content__heading mb-0 mt-5">
            Book a demo with
          </h1>
          <h1 className="Demo__heading banner2__content__heading mt-0">
            our team
          </h1>
          <p className="Demo__para banner2__content__para mt-xl-3 mt-2 text-white-50 fw-light">
            Get a guided demo through egtos, and find out how you or your team
            can change the way you work with consultants, forever.
          </p>
        </div>
        <div className="d-flex justify-content-center mb-sm-0 mb-5">
          <InlineWidget url="https://calendly.com/egtos_booking/30min" />
        </div>
      </div>
    </div>
  );
};

export default Demo;
