import React from "react";
import "./style.css";

function Section1() {
  return (
    <div className="Section1 main-padding" id="scrollToBottom">
      <div className="container-xl px-0 pb-5 pt-lg-5 ">
        <div className="Section1__content mt-lg-5">
          <h1 className="Section1__content__heading Section1__content__heading--darkgrey mt-0">
            Next Generation Consulting.
          </h1>
          <h1 className="Section1__content__heading Section1__content__heading--blue">
            High-performance consulting from global expert teams.
          </h1>
          <p className="Section1__content__para mt-md-5 mt-4">
            We build teams that work. egtos attracts diverse global expert
            talent, so we bring the right people, with the right skills, from
            all over the world to your project. egtos’ AI not only matches
            experts’ skills to your project but ensures the experts we provide
            work well as a team. egtos offers a secure, centralized platform for
            you to easily manage teams and projects. Experts are uniquely
            incentivized and empowered to ensure your success. 
          </p>
          <p className="Section1__content__para Section1__content--bold">egtos: a truly unfair advantage.</p>

          <button className="blue_btn rounded-pill py-2 px-4 shadow my-4">Start outperforming the competition</button>
        </div>
      </div>
    </div>
  );
}

export default Section1;
