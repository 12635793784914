import React from "react";
import "./style.css";

const card = [
  {
    id: 1,
    class: "Services__card1",
    imgclass: "Services__card__top--img1",
    title: "Strategic intent:",
    para: `We help you define your strategy and how to align your operating model to succeed.`,
  },
  {
    id: 2,
    class: "Services__card2",
    imgclass: "Services__card__top--img2",
    title: "Strategy execution:",
    para: ` We help create an execution plan and discipline, we identify and mitigate risks to enable the effective execution of your roadmap.`,
  },
  {
    id: 3,
    class: "Services__card3",
    imgclass: "Services__card__top--img3",
    title: "Operational innovation:",
    para: "We help you innovate, creating structural advantage and scale.",
  },
];

const Section5 = () => {
  return (
    <div className="Services main-padding">
      <div className="container-xl px-0 pt-sm-5 pt-3 pb-sm-5">
        <h1 className="Services__heading mt-sm-5 mt-4">Our services</h1>
        <p className="Industry__para Description__para Teamwork__card__para mb-md-4 mb-3">
          We help clients in all industries accelerate growth with our global
          expert teams. Here are some ways we can help.
        </p>
        <div className="row pb-sm-5 pb-3 pt-sm-4 flex-row Services__row">
          {card.map((item) => (
            <div className="col-lg-4 col-sm-6 mb-lg-0 mb-4" key={item.id}>
              <div
                className={`Services__card ${item.class} pt-lg-5 pt-sm-4 pt-2 pe-xxl-2 pe-lg-3 pe-2 h-100`}
              >
                <div className="Services__card__top d-flex align-items-end">
                  <img
                    src={`/images/Service_${item.id}.svg`}
                    alt="value"
                    className={`Services__card__top--img ${item.imgclass} mb-sm-4 mb-3`}
                  />
                </div>
                <h6 className="Services__card__title ">{item.title}</h6>
                <p className="Services__card__para">{item.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section5;
