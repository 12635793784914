import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Demo from "../pageComponents/RequestADemo/Demo";

const RequestADemo = () => {
  return (
    <>
      <Navbar />
      <Demo />
      <Footer />
    </>
  );
};

export default RequestADemo;
