import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

function Section7() {
  return (
    <div className="Section5 main-padding py-md-0 py-5">
      <div className="container-xl px-0">
        <div className="row">
          <div className="col-md-8 d-flex align-items-center order-md-1 order-2">
            <div className="Section5__content">
              <h1 className="Section5__content__heading Section1__content__heading">
              The egtos difference
              </h1>
              <p className="Section5__content__para Section1__content__para">
              Are you ready for your organization to accelerate past the competition? For your projects to be completed in a fraction of the time? For success to be easier to achieve?
              </p>

              <button className="Section5__content--button mt-4">
                <Link to={'/request-a-demo'} className="Section5__content--button--Link text-decoration-none w-100 d-flex justify-content-center">
                    Book a call
                </Link>
              </button>
            </div>
          </div>

          <div className="col-md-4 my-md-5 mb-3 d-flex justify-content-md-end justify-content-start align-items-center order-md-2 order-1">
            <img src='/images/section5.svg' alt="demo" className="Section5--img mt-sm-4 mb-4"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section7;
