import React from "react";
import "./style.css";

function Image() {
  return (
    <div className="banner__image">
      <img src="/images/homeHero.jpg" alt="" className="banner__image--img" />
    </div>
  );
}

export default Image;
