import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import RequestADemo from './pages/RequestADemo';
function App() {
  return (
    <>
    <Routes>
    <Route path='/' element={<Home />}/>
    <Route path='/request-a-demo' element={<RequestADemo />}/>
    <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
    <Route path='/terms-of-use' element={<TermsOfUse />}/>
    </Routes>
    </>
  );
}

export default App;
