import React from "react";
import "./style.css";
import {HiOutlineArrowDown} from 'react-icons/hi'

function Banner() {
  return (
    <div className="banner ">
      <div className="banner__overlay d-lg-flex align-items-center h-100 pt-5">
      <div className="container-xl px-0 position-relative d-lg-flex align-items-center h-100">
        <div className=" main-padding ">
          <div className="banner__content d-flex flex-column justify-content-center">
          <h1 className="banner__content__heading">
            <span className="golden">Dynamic Teams.</span> <br /> Experts Only. <br /> Global Reach.
          </h1>
          <p className="banner__content__para">
            Build the right team for your critical projects with our global
            experts at a fraction of the cost of traditional consulting.
          </p>
          </div>
          <a href="#scrollToBottom" className=" fs-1 Scroll" id="scroll"><HiOutlineArrowDown className="Scroll__icon transition" /></a>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Banner;
