import React, {useState, useEffect} from "react";
import "./style.css";
import { Link } from "react-router-dom";

function Navbar() {
  const [bg, setBg] = useState("Navbar__transparent");
  const [margin, setMargin] = useState("mt-4");

  const changeBackground = () => {
    if (window.scrollY >= 35) {
      setBg("Navbar__black")
      setMargin("")
    } else {
      setBg("Navbar__transparent")
      setMargin("mt-3")
      
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <>
      <div className={`Navbar main-padding ${bg}`}>
        <div className="container-xl px-0 py-sm-3 py-2 Navbar__container">
        <Link to={"/"}>
          <img src="/images/logo.svg" alt="logo" className={`Navbar__logo ${margin} transition`} />
        </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;
